<template>
  <div>
    <app-header @show="handleChildEvent" @showstatus="showstatus"></app-header>
    <app-main
      :show="show"
      @show="handleChildEvent"
      @showstatus="showstatus"
      :status="status"
    ></app-main>
    <router-view
      @show="handleChildEvent"
      @showstatus="showstatus"
    ></router-view>
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader";
import AppMain from "./components/AppMain";
export default {
  components: { AppHeader, AppMain },
  data() {
    return {
      show: false,
      status: 1,
    };
  },
  methods: {
    handleChildEvent(payload) {
      this.show = payload;
    },
    showstatus(payload) {
      this.show = payload[0];
      this.status = payload[1];
    },
  },
};
</script>

<style scoped>
/* 头部样式 */
.header {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  background-color: transparent;
  z-index: 99;
}

/* 左侧样式 */
.navbar {
  position: absolute;
  width: 200px;
  top: 50px; /* 距离上面50像素 */
  left: 0px;
  bottom: 0px;
  overflow-y: auto; /* 当内容过多时y轴出现滚动条 */
  background-color: #545c64;
}

/* 主区域 */
.main {
  position: fixed;
  width: 100%;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  overflow-y: auto; /* 当内容过多时y轴出现滚动条 */
  /* background-color: red; */
  z-index: 100;
}
</style>
