<template>
  <div class="main" v-if="show" @click.self="sendDataToParent">
    <div class="loginbox" v-if="open == 1">
      <img src="@/assets/login.png" alt="" class="loginimg" />
      <!-- 登录 -->
      <div class="loginform" v-if="status == 1">
        <img src="@/assets/logozh.png" alt="" v-if="language == 1" />
        <img src="@/assets/logoen.png" alt="" v-if="language == 2" />
        <div class="logintitle">{{ cutList.welcome }}</div>
        <el-input v-model="phone" :placeholder="cutList.enterPhone"></el-input>
        <el-input
          v-model="password"
          :placeholder="cutList.enterPass"
          show-password
        ></el-input>
        <div class="loginbtnbox">
          <div class="loginbtn" @click="Login">{{ cutList.ringup }}</div>
        </div>
        <div class="agreementbox">
          <img
            src="@/assets/nochoose.png"
            alt=""
            v-if="!choose"
            @click="choose = true"
          />
          <img
            src="@/assets/choose.png"
            alt=""
            v-else
            @click="choose = false"
          />
          <div class="choosetext">
            {{ cutList.read }}
            <span @click="open = 2">{{ cutList.agreement }}</span>
          </div>
        </div>
        <div class="footer">
          <div class="forget" @click="status = 3">
            {{ cutList.forget }}
          </div>
          <div class="nouser">
            {{ cutList.yet }}
            <span @click="status = 2">{{ cutList.enroll }}！</span>
          </div>
        </div>
      </div>
      <!-- 注册 -->
      <div class="loginform" v-if="status == 2">
        <img src="@/assets/LOGO-b.png" alt="" />
        <div class="logintitlebox">
          <div
            :class="tabselect == 0 ? 'activetabbos' : 'tabbox'"
            @click="tabselect = 0"
          >
            {{ cutList.individuals }}
          </div>
          <div
            :class="tabselect == 1 ? 'activetabbos' : 'tabbox'"
            @click="tabselect = 1"
          >
            {{ cutList.agent }}
          </div>
        </div>
        <el-input
          v-model="newphone"
          :placeholder="cutList.enterPhone"
        ></el-input>
        <div class="codebox">
          <el-input
            v-model="newcode"
            :placeholder="cutList.enterCode"
          ></el-input>
          <div class="getcode" v-if="count == 0" @click="getCode1">
            {{ cutList.security }}
          </div>
          <div class="getcode" v-else>{{ count }}s</div>
        </div>
        <el-input
          v-model="newpassword"
          :placeholder="cutList.enterPass"
          show-password
        ></el-input>
        <div class="loginbtnbox1">
          <div class="loginbtn" @click="register">{{ cutList.enroll }}</div>
        </div>
        <div class="tologintext">
          {{ cutList.young
          }}<span @click="status = 1">{{ cutList.database }}</span>
        </div>
      </div>
      <!-- 完成注册 -->
      <div class="loginform" v-if="status == 4">
        <img src="@/assets/LOGO-b.png" alt="" />
        <div class="inputinfo">{{ cutList.companyinfo }}</div>
        <el-input
          v-model="nickname"
          :placeholder="cutList.enterbusiness"
        ></el-input>
        <el-input v-model="email" :placeholder="cutList.enteremail"></el-input>
        <div class="loginbtnbox1">
          <div class="loginbtn" @click="finishregister">
            {{ cutList.completeRegistration }}
          </div>
        </div>
        <div class="tologintext">
          {{ cutList.young
          }}<span @click="status = 1">{{ cutList.database }}</span>
        </div>
      </div>
      <!-- 忘记密码 -->
      <div class="loginform" v-if="status == 3">
        <img src="@/assets/LOGO-b.png" alt="" />
        <div class="logintitle">{{ cutList.find }}</div>
        <el-input
          v-model="newphone1"
          :placeholder="cutList.enterPhone"
        ></el-input>
        <div class="codebox">
          <el-input
            v-model="newcode1"
            :placeholder="cutList.enterPhone"
          ></el-input>
          <div class="getcode" v-if="count == 0" @click="getCode2">
            {{ cutList.security }}
          </div>
          <div class="getcode" v-else>{{ count }}s</div>
        </div>
        <el-input
          v-model="newpassword1"
          :placeholder="cutList.enterPass"
          show-password
        ></el-input>
        <div class="loginbtnbox1">
          <div class="loginbtn" @click="submit">{{ cutList.verify }}</div>
        </div>
        <div class="tologintext">
          {{ cutList.young
          }}<span @click="status = 1">{{ cutList.database }}</span>
        </div>
      </div>
    </div>
    <div class="loginbox1" v-if="open == 2">
      <div class="back" @click="open = 1">{{ cutList.back }}</div>
      <div v-html="showHtml(paas)"></div>
    </div>
  </div>
</template>

<script>
import {
  adminLogin,
  getcode,
  userLogin,
  retrieve,
  userAgreement,
} from "@/api/api";
export default {
  name: "AppMain",
  props: ["show", "status"],
  data() {
    return {
      phone: "",
      password: "",
      choose: false,
      tabselect: 0,
      newphone: "",
      newpassword: "",
      newcode: "",
      newphone1: "",
      newpassword1: "",
      newcode1: "",
      count: "",
      timer: null,
      open: "1",
      key: "",
      paas: "",
      language: 1,
      nickname: "",
      email: "",
    };
  },
  computed: {
    cutList() {
      return this.$t("m");
    },
  },
  mounted() {
    let geturl = window.location.href;
    let getqyinfo = geturl.split("?")[1]; //qycode=1001&qyname=%E4%BC%81%E4%B8%9A%E5%BF%99   截取到参数部分
    let getqys = new URLSearchParams("?" + getqyinfo); //将参数放在URLSearchParams函数中
    this.key = getqys.get("key"); //1001
    this.getuserAgreement();
    if (sessionStorage.getItem("cut")) {
      if (sessionStorage.getItem("cut") == "zh-CN") {
        this.language = 1;
      } else if (sessionStorage.getItem("cut") == "en-US") {
        this.language = 2;
      }
    }
  },
  methods: {
    // 获取用户协议
    async getuserAgreement() {
      try {
        const res = await userAgreement({ aid: 2 });
        this.paas = res.data.content;
      } catch (error) {
        console.log(error, "获取用户协议失败");
      }
    },
    // 富文本处理
    showHtml(str) {
      return str
        .replace(str ? /&(?!#?\w+;)/g : /&/g, "&amp;")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'")
        .replace(/&amp;nbsp;/g, "\u3000");
    },
    sendDataToParent() {
      this.show = false;
      this.$emit("show", false);
    },
    // 获取短信验证码-注册
    async getCode1() {
      //axios请求
      try {
        const res = await getcode({ phone: this.newphone });
        console.log(res, "发送验证码成功");
        // 验证码倒计时
        if (!this.timer) {
          this.count = 60;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= 60) {
              this.count--;
            } else {
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      } catch (error) {
        console.log(error, "发送验证码失败");
      }
    },
    // 获取短信验证码-重置密码
    async getCode2() {
      //axios请求
      try {
        const res = await getcode({ phone: this.newphone1 });
        console.log(res, "发送验证码成功");
        // 验证码倒计时
        if (!this.timer) {
          this.count = 60;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= 60) {
              this.count--;
            } else {
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      } catch (error) {
        console.log(error, "发送验证码失败");
      }
    },
    // 登录
    async Login() {
      if (this.phone == "") {
        this.$message({
          message: this.cutList.enterPhone,
          type: "warning",
        });
      } else if (this.password == "") {
        this.$message({
          message: this.cutList.pin,
          type: "warning",
        });
      } else if (!this.choose) {
        this.$message({
          message: this.cutList.enterread,
          type: "warning",
        });
      } else {
        try {
          const res = await userLogin({
            username: this.phone,
            pwd: this.password,
          });
          console.log(res, "登录成功");
          this.$message({
            message: this.cutList.enterlogin,
            type: "success",
          });
          this.show = false;
          sessionStorage.setItem("user_id", res.data.id);
          sessionStorage.setItem("user_img", res.data.user_img);
          sessionStorage.setItem("nickname", res.data.nickname);
          location.reload();
          this.phone = "";
          this.password = "";
        } catch (error) {
          console.log(error, "登录失败");
          this.$message.error(error.msg);
        }
      }
    },
    // 注册
    register() {
      if (this.newphone == "") {
        this.$message({
          message: this.cutList.enterPhone,
          type: "warning",
        });
      } else if (this.newcode == "") {
        this.$message({
          message: this.cutList.enterCode,
          type: "warning",
        });
      } else if (this.newpassword == "") {
        this.$message({
          message: this.cutList.pin,
          type: "warning",
        });
      } else {
        // finishregister
        if (this.tabselect == 1) {
          this.$alert(this.cutList.RegistrationTip, this.cutList.hint, {
            confirmButtonText: this.cutList.ensure,
            callback: (action) => {
              this.status = 4;
            },
          });
        } else {
          this.status = 4;
        }
        // try {
        //   const res = await adminLogin({
        //     is_enterprise: this.tabselect,
        //     yqm_type: 2,
        //     yqm: this.key,
        //     yzm: this.newcode,
        //     username: this.newphone,
        //     pwd: this.newpassword,
        //   });
        //   console.log(res, "注册成功");
        //   this.$message({
        //     message: this.cutList.successfully,
        //     type: "success",
        //   });
        //   this.status = 1;
        //   this.newphone = "";
        //   this.newcode = "";
        //   this.newpassword = "";
        // } catch (error) {
        //   console.log(error, "注册失败");
        //   this.$message.error(error.msg);
        // }
      }
    },
    async finishregister() {
      if (this.nickname == "") {
        this.$message({
          message: this.cutList.enterbusiness,
          type: "warning",
        });
      } else if (this.email == "") {
        this.$message({
          message: this.cutList.enteremail,
          type: "warning",
        });
      } else {
        try {
          const res = await adminLogin({
            is_enterprise: this.tabselect,
            yqm_type: 2,
            yqm: this.key,
            yzm: this.newcode,
            username: this.newphone,
            pwd: this.newpassword,
            nickname: this.nickname,
            email: this.email,
          });
          console.log(res, "注册成功");
          // if (this.tabselect == 1) {
          //   this.$message({
          //     message: this.cutList.RegistrationTip,
          //     type: "success",
          //   });
          // } else {
          //   this.$message({
          //     message: this.cutList.successfully,
          //     type: "success",
          //   });
          // }
          this.$message({
            message: this.cutList.successfully,
            type: "success",
          });
          this.status = 1;
          this.newphone = "";
          this.newcode = "";
          this.newpassword = "";
          this.nickname = "";
          this.email = "";
        } catch (error) {
          console.log(error, "注册失败");
          this.$message.error(error.msg);
        }
      }
    },
    // 忘记密码
    async submit() {
      if (this.newphone1 == "") {
        this.$message({
          message: this.cutList.enterPhone,
          type: "warning",
        });
      } else if (this.newcode1 == "") {
        this.$message({
          message: this.cutList.enterCode,
          type: "warning",
        });
      } else if (this.newpassword1 == "") {
        this.$message({
          message: this.cutList.pin,
          type: "warning",
        });
      } else {
        try {
          const res = await retrieve({
            username: this.newphone1,
            yzm: this.newcode1,
            pwd: this.newpassword1,
          });
          console.log(res, "重置密码成功");
          this.$message({
            message: this.cutList.utton,
            type: "success",
          });
          this.status = 1;
          this.newphone1 = "";
          this.newcode1 = "";
          this.newpassword1 = "";
        } catch (error) {
          console.log(error, "重置密码失败");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  background: rgba(000, 000, 000, 0.6);
  border-radius: 0px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  .loginbox {
    width: 740px;
    height: 462px;
    background: #ffffff;
    border-radius: 16px 16px 16px 16px;
    display: flex;
    justify-content: space-between;
    .loginimg {
      width: 50%;
      height: 100%;
    }
    .loginform {
      width: 434px;
      height: 462px;
      background: #ffffff;
      border-radius: 16px 16px 16px 16px;
      margin-left: -154px;
      padding: 32px 16px 16px 16px;
      box-sizing: border-box;
      text-align: center;
      .inputinfo {
        font-size: 18px;
        color: #ff7214;
        margin-top: 32px;
        margin-bottom: 16px;
      }
      .logintitlebox {
        display: flex;
        justify-content: space-evenly;
        margin-top: 28px;
        margin-bottom: 16px;
        .activetabbos {
          font-size: 18px;
          color: #ff7214;
          cursor: pointer;
          padding-bottom: 10px;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            bottom: 0px;
            left: 25%;
            width: 60px;
            height: 2px;
            background: #ff7214;
          }
        }

        .tabbox {
          font-size: 18px;
          color: #999999;
          cursor: pointer;
        }
      }
      .logintitle {
        font-size: 18px;
        color: #ff7214;
        margin-top: 32px;
        margin-bottom: 16px;
      }
      ::v-deep .el-input {
        font-size: 16px;
        width: 370px;
        height: 48px;
        margin-bottom: 16px;
        border-radius: 9px 9px 9px 9px;
      }
      ::v-deep .el-input__inner {
        width: 370px;
        height: 48px;
        border-radius: 9px 9px 9px 9px;
      }
      // placeholder 样式修改
      :deep input::-webkit-input-placeholder {
        font-size: 16px;
        color: #cccccc;
      }

      .codebox {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
        ::v-deep .el-input {
          font-size: 16px;
          width: 240px;
          height: 48px;
          border-radius: 9px 9px 9px 9px;
          margin-bottom: 0;
          margin-right: 10px;
        }
        ::v-deep .el-input__inner {
          width: 240px;
          height: 48px;
          border-radius: 9px 9px 9px 9px;
        }

        .getcode {
          width: 120px;
          height: 48px;
          background: #fff2ea;
          border-radius: 9px 9px 9px 9px;
          font-size: 16px;
          color: #ff7214;
          line-height: 48px;
          text-align: center;
          cursor: pointer;
        }
      }
      .loginbtnbox {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 28px;
        .loginbtn {
          width: 370px;
          height: 48px;
          background: #ff7214;
          border-radius: 9px 9px 9px 9px;
          font-size: 16px;
          color: #ffffff;
          line-height: 48px;
          text-align: center;
          cursor: pointer;
        }
      }

      .loginbtnbox1 {
        width: 100%;
        display: flex;
        justify-content: center;
        .loginbtn {
          width: 370px;
          height: 48px;
          background: #ff7214;
          border-radius: 9px 9px 9px 9px;
          font-size: 16px;
          color: #ffffff;
          line-height: 48px;
          text-align: center;
          cursor: pointer;
        }
      }

      .tologintext {
        text-align: center;
        font-size: 16px;
        color: #666666;
        margin-top: 10px;
        span {
          cursor: pointer;
          color: #ff7214;
        }
      }
      .agreementbox {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 16px;
        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          cursor: pointer;
        }
        .choosetext {
          font-size: 16px;
          color: #cccccc;
          span {
            color: #ff6600;
            cursor: pointer;
          }
        }
      }

      .footer {
        margin-top: 16px;
        padding-top: 16px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #eeeeee;
        .forget {
          font-size: 16px;
          color: #666666;
          cursor: pointer;
        }
        .nouser {
          font-size: 16px;
          color: #666666;
        }
        span {
          cursor: pointer;
          color: #ff7214;
        }
      }
    }
  }

  .loginbox1 {
    width: 740px;
    height: 462px;
    background: #ffffff;
    border-radius: 16px 16px 16px 16px;
    padding: 24px;
    padding-top: 48px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden; // 隐藏超出部分
    overflow-y: scroll; // 设置纵向滚动效果，x为横向
    .back {
      font-size: 16px;
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
      color: #ff7214;
    }
  }
}
</style>
